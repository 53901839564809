import React from 'react';

const ContactBlock = (props) => {
    // const [region, setRegion] = React.useState('');
    const [name, setName] = React.useState('');

    return (<>
        {/* Contact */}
        <div className="energy-calculator-contact-block">
            <div className='energy-calculator-contact-block__banner'>
                <div>
                    <h2>{props.contactHeading}</h2>
                    <p>{props.contactSubHeading}</p>
                </div>
            </div>
            <div className='energy-calculator-contact-block__img-wrapper'><img src={props.endpoint + props.contactImage.url || '/'} /></div>
            <form>
                <div className="form-item form-item--select ">
                    <label for="pet-select" className="form-item__label">
                        Vänligen välj region
                    </label>
                    <select id="pet-select" className="select__select " name="Select" onChange={(event) => setName(event.target.value)}>
                        <option value="">-- Välj region -- </option>
                        <option value="syd">Syd</option>
                        <option value="väst">Väst</option>
                        <option value="mellan">Mellan</option>
                        <option value="stockholm">Stockholm</option>
                        <option value="norr">Norr</option>
                    </select>

                </div>
                <div className="form-item  ">
                    <label for="input-field" className="form-item__label">
                        Namn
                    </label>
                    <input id="input-field" type="text" className="input__input" />

                </div>
                <div className="form-item  ">
                    <label for="input-field" className="form-item__label">
                        Email
                    </label>
                    <input id="input-field" type="text" className="input__input" />

                </div>
                <div className="form-item  ">
                    <label for="input-field" className="form-item__label">
                        Telefon
                    </label>
                    <input id="input-field" type="text" className="input__input" />

                </div>
                <div>
                    <a className="button  ">
                        <span className="button__label">Skicka</span>
                    </a>
                </div>
            </form>
            {name &&
                <>
                    <div className='energy-calculator-contact-block__contact-card'>

                        <div className="contact-card contact-card--person ">
                            <div className="contact-card__body">
                                <div className="contact-card__top-img">
                                    <img src={props.endpoint + props.contactImage.url || '/'} alt="Contact Page Image" className="contact-card__icon" />
                                </div>
                                <strong className="contact-card__label">John Doe</strong>
                                <div className="contact-card__bottom-body">
                                    <div className="contact-card__role">Säljansvarig</div>
                                    <div className="js-options-limiter" data-option-selector=".js-option" data-option-limit="3">
                                        <div className="js-option">{name}</div>
                                    </div>
                                </div>
                            </div>

                            <div id="" className="show-info js-show-info ">
                                <div className="show-info__wrapper js-show-info-wrapper">
                                    <div className="show-info__content">

                                        <ul className="contact-list">
                                            <li className="contact-list__item">
                                                <span href="">
                                                    <svg className="icon contact-list__icon" focusable="false">
                                                    </svg>
                                                    Kompanigatan 1-2, 553 05<br />Kalmar
                                                </span>
                                            </li>
                                            <li className="contact-list__item">
                                                <a href="tel:031 1234 5678">
                                                    <svg className="icon contact-list__icon" focusable="false">
                                                    </svg>
                                                    031 1234 5678
                                                </a>
                                            </li>
                                            <li className="contact-list__item">
                                                <a href="mailto:kungstensvent.anders@gmail.com">
                                                    <svg className="icon contact-list__icon" focusable="false">
                                                    </svg>
                                                    kungstensvent@gmail.com
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>

                    <div className="energy-calculator-contact-block__overlay"></div>
                </>

            }
        </div>

    </>
    );
};

export default ContactBlock;
