import React from 'react';
import PropTypes from 'prop-types';
import Icon from '../../../icon';

const ResultCard = (props) => {
    return (
        <div className={`calculator-result__result-wrapper ${props.disabled && 'is-disabled'}`} tabIndex={0}>
            <div className="calculator-result__procent">{props.percentValue}%</div>
            {props.children}
            <div>
                <div className="calculator-result__energy-savings">
                    <p>{props.energySavings}</p>
                    <Icon icon='information' additionalClasses="calculator-result__item--icon" />
                </div>
                <p className='calculator-result__preamble'>{props.preamble}</p>
            </div>
        </div>
    );
};

ResultCard.propTypes = {
    percentValue: PropTypes.number,
    preamble: PropTypes.string,
    energySavings: PropTypes.string,
    disabled: PropTypes.bool
};

export default ResultCard;
