import React from 'react';
import Icon from '../../../icon';
import Accordion from '../Accordion';
import ResultCard from './ResultCard';
import { CalculationContext } from '../../context/CalculationContext';
import Input from '../../../form/input/Input';
import ProductAndServices from './components/AdditionalSettings/ProductsAndServices';
import Lottie from 'lottie-react';
import animation1 from '../../assets/swegon-1.json';
import animation2 from '../../assets/swegon-2.json';
import animation3 from '../../assets/swegon-3.json';

const CalculatorBlock = (props) => {

    const [downloadPdfDisabled, setDownloadPdfDisabled] = React.useState(true);
    const [startCount, setStartCount] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [count, setCount] = React.useState(0);

    const calculationContext = React.useContext(CalculationContext);
    calculationContext.setLang(props.language.name);
    calculationContext.setEndpoint(props.endpoint);

    const handleDialogPopup = (version) => {
        let dialog;
        switch (version) {
            case 'dowloadPdf':
                dialog = document.querySelector('.calculator-result__dialog-pdf');
                break;

            case 'additionalSettings':
                dialog = document.querySelector('.calculator-result__dialog-additional-settings');
                break;

            case 'result':
                setStartCount(!startCount);
                dialog = document.querySelector('.calculator__result-dialog');
                dialog.addEventListener('keydown', function (event) {
                    if (event.key === 'Escape') {
                        event.preventDefault(); // Prevent the dialog from closing
                    }
                });
                break;

            default:
                break;
        }
        if (dialog.hasAttribute('open'))
            dialog.close();
        else
            dialog.showModal();
    };

    // Loader
    React.useEffect(() => {
        const duration = 1500; // 1.5 seconds
        const increment = 100; // counting from 0 to 100
        const intervalTime = duration / increment; // time per increment
        if (startCount) {
            setCount(0);
            setLoading(true);

            const interval = setInterval(() => {
                setCount((prevCount) => {
                    if (prevCount < 100) {
                        return prevCount + 1;
                    } else {
                        handleDialogPopup('result');
                        setLoading(false);
                        clearInterval(interval);
                        return prevCount;
                    }
                });
            }, intervalTime);

            return () => clearInterval(interval); // Cleanup interval on unmount
        }
    }, [startCount]);

    const firstSettings = <>

        {/* Select Country */}
        <Input label={props.translations.countryLabel} id='country' onChange={calculationContext.setCountry}>
            {props.countries.map((option) => <option selected={option.value.country === calculationContext.country} option={option.value.country}>{option.value.country}</option>)}
        </Input>

        {/* Select Construction Type */}
        <Input label={props.translations.applicationLabel} id='constructionType' onChange={calculationContext.setApplication}> {props.applications.map((option) => <option selected={option.value.application === calculationContext.application} option={option.value.application}>{option.value.application}</option>)}</Input>

        {/* Select New building */}
        <div className="form-item form-item--select ">
            <label htmlFor={'newBuilding'} className="form-item__label">
                {props.translations.buildingTypeLabel}
            </label>
            <select id={'newBuilding'} onChange={(e) => {
                const isNewBuilding = e.target.value === props.translations.newBuildingLabel;
                calculationContext.setNewBuilding(isNewBuilding);
                calculationContext.setEnergyUsePerSquareMeter(isNewBuilding ? calculationContext.energyUseSqMetersNew : calculationContext.energyUseSqMetersExisting);
            }} className="select__select " name="Select" >

                <option selected={calculationContext.newBuilding === true} value={props.translations.newBuildingLabel}>{props.translations.newBuildingLabel}</option>
                <option selected={calculationContext.newBuilding === false} value={props.translations.existingBuildingLabel}>{props.translations.existingBuildingLabel}</option>

            </select>

        </div>

        <div className="form-item  ">
            <label htmlFor="input-field" className="form-item__label">
                {props.translations.sizeInSqmLabel}
            </label>
            <input id="input-field" value={calculationContext.sizeOfBuilding} type="number" className="input__input" onChange={e => calculationContext.setSizeOfBuilding(Number(e.target.value))} />
        </div>
    </>;

    React.useEffect(() => {

        const initialResponse = {
            'country': props.countries[0].value.country,
            'application': props.applications[0].value.application,
            'newBuilding': true,
            'sizeOfBuilding': props.defaultBuildingSize,
            'energyUsePerSquareMeter': props.countries[0].value.estimatedEnergyUsageNewBuilding,
            'heatingType': props.heatingTypes[0].value.name,
            'heatingProductionEfficiency': props.heatingTypes[0].value.efficiencyFactor,
            'hotwaterProduction': props.hotwaterTypes[0].value.name,
            'hotwaterProductionEfficiency': props.hotwaterTypes[0].value.efficiencyFactor,
            'coolingProduction': props.coolingTypes[0].value.name,
            'coolingProductionEfficiency': props.coolingTypes[0].value.efficiencyFactor,
            'energyUseSqMetersExisting': props.countries[0].value.estimatedEnergyUsageExistingBuilding,
            'currency': props.countries[0].value.currency

        };

        calculationContext.setCountry(initialResponse.country);
        calculationContext.setApplication(initialResponse.application);
        calculationContext.setHeatingType(initialResponse.heatingType);
        calculationContext.setHotWaterProduction(initialResponse.hotwaterProduction);
        calculationContext.setCoolingProduction(initialResponse.coolingProduction);

        calculationContext.setSizeOfBuilding(initialResponse.sizeOfBuilding);
        calculationContext.setNewBuilding(initialResponse.newBuilding);
        calculationContext.setEnergyUsePerSquareMeter(initialResponse.energyUsePerSquareMeter);
        calculationContext.setHeatingProductionEfficiency(initialResponse.heatingProductionEfficiency);
        calculationContext.setHotWaterProductionEfficiency(initialResponse.hotwaterProductionEfficiency);
        calculationContext.setCoolingProductionEfficiency(initialResponse.coolingProductionEfficiency);

        calculationContext.setEnergyUseSqMetersNew(initialResponse.energyUsePerSquareMeter);
        calculationContext.setEnergyUseSqMetersExisting(initialResponse.energyUseSqMetersExisting);

        calculationContext.setCurrency(initialResponse.currency);

    }, []);

    return (
        <> {/* Calculator */}
            <div className='calculator-wrapper'>
                <div className='calculator'>
                    <div
                        className='lottie-wrapper'
                    >
                        <Lottie animationData={animation1} />
                        <Lottie animationData={animation2} />
                        <Lottie animationData={animation3} />
                    </div>
                    <div className='calculator__banner-wrapper'>
                        <div className='calculator__banner' role="banner">
                            <div role="doc-subtitle" className="tagline">{props.tagLine}</div>
                            <h1 className='calculator__heading'>{props.heading}</h1>
                        </div>
                        <div dangerouslySetInnerHTML={{ __html: props.preamble }}></div>
                    </div>
                    <div className='calculator__input-wrapper'>
                        {firstSettings}
                    </div>
                    <div className='calculator__disclaimer' dangerouslySetInnerHTML={{ __html: props.calculateDisclaimer }}></div>
                    <div className='calculator__button'>
                        <a className="button button--rounded calculator__calculate-button" onClick={() => {
                            setDownloadPdfDisabled(false);
                            handleDialogPopup('result');
                            calculationContext.calculate();
                        }
                        }>
                            <span className="button__label">{props.translations.calculateLabel}</span>
                        </a>
                    </div>
                </div>

                {/* Result popup */}
                <dialog className="calculator__result-dialog">
                    <div>
                        <h2>{count}%</h2>
                        <div className="calculator__result-dialog--label" >
                            <p>{props.translations.loaderDisclaimer}</p>
                        </div>
                    </div>
                </dialog>

                {/* Result */}
                <div className={`calculator-result ${downloadPdfDisabled && 'disabled'}`} >
                    <div className="calculator-result__download-pdf">
                        <a onClick={() => !downloadPdfDisabled && handleDialogPopup('dowloadPdf')} className={downloadPdfDisabled && 'disabled'} disabled={downloadPdfDisabled}>
                            <span className="">{props.translations.saveResultsLabel}</span>
                            <Icon icon='download' additionalClasses="calculator-result__item--icon" />
                        </a>
                    </div>

                    {/* Download PDF popup */}
                    <dialog className="calculator-result__dialog-pdf">
                        <div className="calculator-result__dialog-pdf--inner">
                            <h2 className='headline-3'>{props.emailSubject}</h2>
                            <p>{props.emailBody}</p>
                            <form>
                                <div className="form-item  ">
                                    <label htmlFor="input-field" className="form-item__label">
                                        {props.translations.emailLabel}*
                                    </label>
                                    <input id="input-field" type="text" className="input__input" placeholder={props.translations.emailPlaceholder} required />

                                </div>
                                <div className="form-item  ">
                                    <label htmlFor="input-field" className="form-item__label">
                                        {props.translations.companyLabel}
                                    </label>
                                    <input id="input-field" type="text" className="input__input" placeholder={props.translations.companyPlaceholder} />

                                </div>
                                <div className="form-item  ">
                                    <label htmlFor="input-field" className="form-item__label">
                                        {props.translations.phoneLabel}
                                    </label>
                                    <input id="input-field" type="text" className="input__input" placeholder={props.translations.phonePlaceholder} />

                                </div>
                            </form>

                            <div>
                                <a className="button  h-margin-top-2">
                                    <span className="button__label">{props.translations.send}</span>
                                </a>
                            </div>

                            <button className='calculator-result__dialog-pdf--close-button' autoFocus onClick={() => handleDialogPopup('dowloadPdf')}>
                                <Icon icon='close' additionalClasses="calculator-result__item--icon"></Icon>
                            </button>
                        </div>
                    </dialog>

                    <div className='calculator-result__card-wrapper'>
                        <ResultCard percentValue={loading ? 0 : calculationContext.EnergySavingsPercentage}
                            preamble={props.translations.energySavingsPreamble}
                            energySavings={props.translations.energySavingsLabel}
                            disabled={downloadPdfDisabled}
                        >
                            <div className="calculator-result__money-savings calculator-result__thermal-energy">{props.translations.thermalSavingsLabel} {calculationContext.ThermalEnergySavings}%</div>
                            <div className="calculator-result__money-savings">{props.translations.electricalSavingsLabel} {calculationContext.ElectricEnergySavings}%</div>

                        </ResultCard>
                        <ResultCard percentValue={loading ? 0 : calculationContext.Co2SavingsPercentage}
                            preamble={props.translations.reducedCo2EmissionsPreamble}
                            energySavings={props.translations.reducedCo2EmissionsLabel}
                            disabled={downloadPdfDisabled}
                        >
                            <div className="calculator-result__money-savings">{loading ? 0 : calculationContext.Co2Savings} {props.translations.kCo2eLabel}/{props.translations.yearLabel}</div>
                        </ResultCard>
                        <ResultCard percentValue={loading ? 0 : calculationContext.FinancialSavingsPercentage}
                            preamble={props.translations.financialSavingsPreamble}
                            energySavings={props.translations.financialSavingsLabel}
                            disabled={downloadPdfDisabled}
                        >
                            <div className="calculator-result__money-savings">{loading ? 0 : calculationContext.FinancialSavings} {calculationContext.currency}/{props.translations.yearLabel}</div>
                        </ResultCard>

                    </div>

                    <div className='calculator-result__additional-info'>
                        <div>
                            <div className='calculator-result__additional-info_heading'>{props.translations.estimatedEnergyUsage}</div>
                            <div className='calculator-result__additional-info_result'><span className='calculator-result__additional-info_value'>{calculationContext.EnergyConsumption}</span> {props.translations.locKwhLabel}/{props.translations.yearLabel}</div>

                        </div>
                        <div>
                            <div className='calculator-result__additional-info_heading'>{props.translations.estimatedEnergySavings}</div>
                            <div className='calculator-result__additional-info_result'><span className='calculator-result__additional-info_value'>{calculationContext.TotalEnergySavings}</span> {props.translations.locKwhLabel}/{props.translations.yearLabel}</div>

                        </div>
                    </div>

                    <div onClick={() => handleDialogPopup('additionalSettings')} className='calculator-result__additional-settings calculator__button'><button disabled={downloadPdfDisabled} className="button button--rounded">{props.translations.additionalSettingsLabel}<Icon icon='filter' additionalClasses="calculator-result__item--icon" /></button></div>

                    {/* Additional settings popup */}
                    <dialog className="calculator-result__dialog-additional-settings">
                        <div className="calculator-result__dialog-additional-settings--inner">
                            <div className='additional-settings__buttons'>
                                <button className='button button--rounded button--ghost' onClick={() => handleDialogPopup('additionalSettings')}>{props.translations.cancel}</button>
                                <button className='button button--rounded' onClick={() => {
                                    calculationContext.calculate();
                                    handleDialogPopup('additionalSettings');
                                    handleDialogPopup('result');
                                }}>{props.translations.calculateLabel}</button>
                            </div>
                            <section>
                                <h2 className='h-margin-bottom-5'>{props.translations.requiredSettingsLabel}</h2>
                                <div className='calculator__input-wrapper'>
                                    {firstSettings}
                                </div>
                            </section>
                            <section className='h-margin-top-2'>
                                <h2 >{props.translations.additionalSettingsLabel}</h2>
                                <p>{props.translations.additionalSettingsPreamble}</p>
                                <Accordion open label={props.translations.calculationSettingsLabel}>
                                    <div className='calculator__input-wrapper'>

                                        {/* Select heating */}
                                        <Input label={props.translations.heatingLabel} id='heatingTypes' onChange={calculationContext.setHeatingType}>
                                            {props.heatingTypes.map((option) => <option selected={option.value.name === calculationContext.heatingType} option={option.value.name}>{option.value.name}</option>)}
                                        </Input>

                                        {/* Select hot water */}
                                        <Input label={props.translations.hotwaterTypesLabel} id='hotwaterTypes' onChange={calculationContext.setHotWaterProduction}>

                                            {props.hotwaterTypes.map((option) => <option selected={option.value.name === calculationContext.hotwaterProduction} option={option.value.name}>{option.value.name}</option>)}
                                        </Input>

                                        {/* Select cooling */}
                                        <Input label={props.translations.coolingLabel} id='coolingTypes' onChange={calculationContext.setCoolingProduction}>
                                            {props.coolingTypes.map((option) => <option selected={option.value.name === calculationContext.coolingProduction} option={option.value.name}>{option.value.name}</option>)}
                                        </Input>
                                    </div>
                                </Accordion>

                                <Accordion open label={props.translations.swegonProductsAndServicesLabel}>
                                    <ProductAndServices props={props} />
                                </Accordion>
                                <Accordion level={1} label={props.translations.advancedSettings}>
                                    <Accordion level={2} label={props.translations.requestedEnergyUsageLabel}>
                                        <div className='calculator-result__dialog-additional-settings__energy-cost'>
                                            <div className='energy-cost__row'>
                                                <p>{calculationContext.newBuilding ? props.translations.energyUseNewLabel : props.translations.energyUseExistingLabel}</p>
                                                <input type="number" onChange={e => calculationContext.setEnergyUsePerSquareMeter(e.target.value)} value={calculationContext.energyUsePerSquareMeter} />
                                                <p>{props.translations.kwhSqmLabel}</p>

                                            </div>

                                        </div>
                                    </Accordion>

                                    <Accordion level={2} label={props.translations.energyCostLabel}>
                                    <p>{props.translations.energyCostPreamble}</p>
                                        <div className='calculator-result__dialog-additional-settings__energy-cost'>
                                            <div className='energy-cost__row'>
                                                <p>{props.translations.electricityLabel}</p>
                                                <input type="number" onChange={e => calculationContext.setEnergyCostElectricity(e.target.value)} value={calculationContext.energyCostElectricity} /><p>{calculationContext.currency}/{props.translations.locKwhLabel}</p>

                                            </div>
                                            <div className='energy-cost__row'>
                                                <p>{props.translations.gasLabel}</p>
                                                <input type="number" onChange={e => calculationContext.setEnergyCostGas(e.target.value)} value={calculationContext.energyCostGas} />
                                                <p>{calculationContext.currency}/{props.translations.locKwhLabel}</p>

                                            </div>
                                            <div className='energy-cost__row'>
                                                <p>{props.translations.oilLabel}</p>
                                                <input type="number" onChange={e => calculationContext.setEnergyCostOil(e.target.value)} value={calculationContext.energyCostOil} />
                                                <p>{calculationContext.currency}/{props.translations.locKwhLabel}</p>

                                            </div>
                                            <div className='energy-cost__row'>
                                                <p>{props.translations.districtHeatingLabel}</p>
                                                <input type="number" onChange={e => calculationContext.setEnergyCostDistrictHeating(e.target.value)} value={calculationContext.energyCostDistrictHeating} />
                                                <p>{calculationContext.currency}/{props.translations.locKwhLabel}</p>

                                            </div>
                                            <div className='energy-cost__row'>
                                                <p>{props.translations.districtCoolingLabel}</p>
                                                <input type="number" onChange={e => calculationContext.setEnergyCostDistrictCooling(e.target.value)} value={calculationContext.energyCostDistrictCooling} />
                                                <p>{calculationContext.currency}/{props.translations.locKwhLabel}</p>

                                            </div>
                                        </div>
                                    </Accordion>
                                    <Accordion level={2} label={props.translations.energyEfficiencyFactorLabel}>
                                        <div className='calculator-result__dialog-additional-settings__energy-cost'>
                                            <div className='energy-cost__row'>
                                                <p>{props.translations.heatingLabel}</p>
                                                <input onChange={e => calculationContext.setHeatingProductionEfficiency(e.target.value)} value={calculationContext.heatingProductionEfficiency} />
                                                <p>{props.translations.kilowattPerKilowatt}</p>

                                            </div>
                                            <div className='energy-cost__row'>
                                                <p>{props.translations.coolingLabel}</p>
                                                <input type="number" onChange={e => calculationContext.setCoolingProductionEfficiency(e.target.value)} value={calculationContext.coolingProductionEfficiency} />
                                                <p>{props.translations.kilowattPerKilowatt}</p>

                                            </div>
                                            <div className='energy-cost__row'>
                                                <p>{props.translations.domesticHotWaterLabel}</p>
                                                <input onChange={e => calculationContext.setHotWaterProductionEfficiency(e.target.value)} value={calculationContext.hotwaterProductionEfficiency} />
                                                <p>{props.translations.kilowattPerKilowatt}</p>

                                            </div>
                                        </div>
                                    </Accordion>
                                    <Accordion level={2} label={props.translations.greenHouseGasEquivalentLabel}>
                                        <div className='calculator-result__dialog-additional-settings__energy-cost'>
                                            <div className='energy-cost__row'>
                                                <p>{props.translations.electricityLabel}</p>
                                                <input type="number" onChange={e => calculationContext.setGHGCo2Electricity(e.target.value)} value={calculationContext.GreenhouseGasCo2Electricity} />
                                                <p>{props.translations.kCo2eLabel}</p>

                                            </div>
                                            <div className='energy-cost__row'>
                                                <p>{props.translations.gasLabel}</p>
                                                <input type="number" onChange={e => calculationContext.setGHGCo2Gas(e.target.value)} value={calculationContext.GreenhouseGasCo2Gas} />
                                                <p>{props.translations.kCo2eLabel}</p>

                                            </div>
                                            <div className='energy-cost__row'>
                                                <p>{props.translations.oilLabel}</p>
                                                <input type="number" onChange={e => calculationContext.setGHGCo2Oil(e.target.value)} value={calculationContext.GreenhouseGasCo2Oil} />
                                                <p>{props.translations.kCo2eLabel}</p>

                                            </div>
                                            <div className='energy-cost__row'>
                                                <p>{props.translations.districtHeatingLabel}</p>
                                                <input type="number" onChange={e => calculationContext.setGHGDistrictHeating(e.target.value)} value={calculationContext.GreenhouseGasDistrictHeating} />
                                                <p>{props.translations.kCo2eLabel}</p>

                                            </div>
                                            <div className='energy-cost__row'>
                                                <p>{props.translations.districtCoolingLabel}</p>
                                                <input type="number" onChange={e => calculationContext.setGHGDistrictCooling(e.target.value)} value={calculationContext.GreenhouseGasDistrictCooling} />
                                                <p>{props.translations.kCo2eLabel}</p>

                                            </div>
                                        </div>
                                    </Accordion>
                                    <Accordion level={2} label={props.translations.energyDivisionLabel}>
                                        <div className='calculator-result__dialog-additional-settings__energy-cost'>
                                            <div className='energy-cost__row'>
                                                <p>{props.translations.energyDivisionHeatingLabel}</p>
                                                <input type="number" onChange={e => calculationContext.setHeatingDivisionPercentage(e.target.value)} value={calculationContext.heatingDivisionPercentage} />
                                                <p>%</p>

                                            </div>
                                            <div className='energy-cost__row'>
                                                <p>{props.translations.energyDivisionCoolingLabel}</p>
                                                <input type="number" onChange={e => calculationContext.setCoolingDivisionPercentage(e.target.value)} value={calculationContext.coolingDivisionPercentage} />
                                                <p>%</p>

                                            </div>
                                            <div className='energy-cost__row'>
                                                <p>{props.translations.energyDivisionHotWaterLabel}</p>
                                                <input type="number" onChange={e => calculationContext.setDomesticHotWaterDivisionPercentage(e.target.value)} value={calculationContext.domesticHotWaterDivisionPercentage} />
                                                <p>%</p>

                                            </div>
                                            <div className='energy-cost__row'>
                                                <p>{props.translations.energyDivisionBuildingElectricityLabel}</p>
                                                <input type="number" onChange={e => calculationContext.setBuildingElectricityDivisionPercentage(e.target.value)} value={calculationContext.buildingElectricityDivisionPercentage} />
                                                <p>%</p>

                                            </div>

                                        </div>
                                    </Accordion>
                                </Accordion>
                            </section>
                            <button className='calculator-result__dialog-additional-settings--close-button' autoFocus onClick={() => handleDialogPopup('additionalSettings')}>
                                <Icon icon='close' additionalClasses="calculator-result__item--icon"></Icon>
                            </button>
                        </div>
                    </dialog>

                </div >
            </div >

        </>
    );
};

export default CalculatorBlock;
